const $ = require('jquery');

const { matchMediaTablet } = require('../lib/media');

class PersonCard {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$triggerNode = this.$node.find('[data-ref~="trigger"]');
        this.$bodyNode = this.$node.find('[data-ref~="body"]');

        // TODO: Attempt to implement this functionality without references to outside nodes
        this.$otherComponentNodes = $('[data-component~="person-card"]').not(this.$node);
        this.$parentListNode = this.$node.closest('[data-ref="list"]');

        this.$triggerNode.on('click', this.handleTriggerClick.bind(this));
        $(window).on('resize', this.resizeBodyNode.bind(this));
    }

    handleTriggerClick () {
        if (this.$node.hasClass('is-open')) {
            this.$node.removeClass('is-open');
            this.$otherComponentNodes.removeClass('is-dimmed');
        } else {
            this.$node.addClass('is-open');
            this.$node.removeClass('is-dimmed');
            this.$otherComponentNodes.removeClass('is-open');
            this.$otherComponentNodes.addClass('is-dimmed');
            window.setTimeout(() => this.resizeBodyNode(), 0);

            if (!matchMediaTablet.matches) {
                if ('scrollBehavior' in document.documentElement.style) {
                    this.$node.get(0).scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                } else {
                    this.$node.get(0).scrollIntoView(true);
                }
            }
        }
    }

    resizeBodyNode () {
        // Find distance between edges of card node and parent list node, and
        // manually set margins to push/pull body node edges to match parent
        if (matchMediaTablet.matches && this.$parentListNode.length) {
            const parentNode = this.$parentListNode.get(0);
            const cardNode = this.$node.get(0);

            const { left: cardLeft, right: cardRight } = cardNode.getBoundingClientRect();
            const { left: parentLeft, right: parentRight } = parentNode.getBoundingClientRect();

            this.$bodyNode.css({
                marginLeft: parentLeft - cardLeft,
                marginRight: cardRight - parentRight,
            });
        } else {
            this.$bodyNode.removeAttr('style');
        }
    }
}

module.exports = PersonCard;
