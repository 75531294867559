const $ = require('jquery');

const { matchMediaTablet } = require('../lib/media');

class Header {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$navToggleNode = this.$node.find('[data-ref~="toggle"]');
        this.$itemNodes = this.$node.find('[data-ref~="item"]');
        this.$itemToggleNodes = this.$node.find('[data-ref~="item-toggle"]');

        this.$navToggleNode.on('click', this.handleToggleClick.bind(this));
        this.$itemToggleNodes.on('click', this.handleItemToggleClick.bind(this));
        this.$itemNodes.on('focusin mouseenter', this.handleItemActivate.bind(this));
        this.$itemNodes.on('mouseleave', this.handleItemDeactivate.bind(this));

        this._handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this);

        if ('addEventListener' in matchMediaTablet) {
            matchMediaTablet.addEventListener('change', this.handleBreakpointChange.bind(this));
        } else {
            matchMediaTablet.addListener(this.handleBreakpointChange.bind(this));
        }
    }

    handleBreakpointChange() {
        this.$itemNodes.removeClass('is-open');
    }

    handleDocumentKeyDown (event) {
        if (event.key === 'Escape') {
            this.closeNav();
        }
    }

    handleItemToggleClick (event) {
        const $toggleNode = $(event.currentTarget);
        const $itemNode = $toggleNode.closest(this.$itemNodes);

        if ($itemNode.hasClass('is-open')) {
            $itemNode.removeClass('is-open');
        } else {
            $itemNode.addClass('is-open');
        }
    }

    handleItemActivate (event) {
        if (!matchMediaTablet.matches) return;
        const $linkNode = $(event.currentTarget);
        const $itemNode = $linkNode.closest(this.$itemNodes);
        this.$itemNodes.removeClass('is-open');
        $itemNode.addClass('is-open');
    }

    handleItemDeactivate (event) {
        if (!matchMediaTablet.matches) return;
        const $linkNode = $(event.currentTarget);
        const $itemNode = $linkNode.closest(this.$itemNodes);
        $itemNode.removeClass('is-open');
    }

    handleToggleClick () {
        if (!this.$node.hasClass('is-open')) {
            this.openNav();
        } else {
            this.closeNav();
        }
        this.$node.toggleClass('is-open');
    }

    closeNav() {
        $(document).off('keydown', this._handleDocumentKeyDown);
    }

    openNav() {
        $(document).on('keydown', this._handleDocumentKeyDown);
    }
}

module.exports = Header;
